@import "../../../styles/colors.scss";
@import "../../../styles/constants.scss";

@keyframes appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.holder {
  height: 60vh;

  .errorMessage {
    color: $white-color;
  }
}

.presentation {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  * {
    color: $white-color;
  }

  .contentControls {
    display: flex;
    justify-content: space-between;

    // for 3 items
    // min-width: 1350px;
    // width: 82vw;
    // max-width: 1580px;

    width: 960px;

    .controlHolder {
      cursor: pointer;
      user-select: none;

      width: 434px;

      display: flex;
      justify-content: center;

      .control {

        .h2 {
          text-align: center;
          font-size: 40px;
          font-weight: 400;
        }

        .underline {
          margin-top: 20px;
          width: 100%;
          height: 0px;
          border: 2.5px solid $blue-color;
          background-color: $blue-color;
          opacity: 0;
          transition: left .4s ease;
        } 
        .active {
          opacity: 1;
          position: absolute;
          left: 0;
        }
      }
    }
    
    .separetor {
      height: 51px;
      border: 0.5px solid rgba($color: $white-color, $alpha: 0.5);
    }

    @media screen and (max-width: $small-screen) {
      // for 3 items
      // min-width: 900px;
      // width: 70vw;
      // max-width: 90%;

      width: 660px;

      .controlHolder {
        width: 305px;

        .control {
          .h2 {
            font-size: 26px;
          }
    
          .underline {
            margin-top: 12px;
            border-width: 1.5px;
          }
        }
      }
      
      .separetor {
        height: 36px;
      }
    }
    @media screen and (max-width: 900px) {
      min-width: unset;
      width: 100%;
      max-width: 800px;
      
      padding-left: 10%;
      padding-right: 10%;

      flex-wrap: wrap;
      row-gap: 20px;

      .controlHolder {
        width: unset;

        .control {
          .underline {
            position: static;
            transition: opacity .25s ease-in-out;
          }
          .active {
            position: static;
          }  
        }
      }

      .lastSeparator {
        display: none;
      }
    }
    @media screen and (max-width: $mobile-screen) {
      padding-left: 5%;
      padding-right: 5%;

      row-gap: 10px;

      .controlHolder {
        .control {
          .h2 {
            font-size: 5.6vw;
          }
        }
      }

      .separetor {
        height: 7.3vw;
      }
    }
  }

  .content {
    margin-top: 160px;
    width: 100vw;

    display: flex;
    align-items: center;

    * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media screen and (max-width: $small-screen) {
      margin-top: 60px;      
    }
    @media screen and (max-width: 900px) {
      margin-top: 0;
      flex-flow: column nowrap;
      align-items: center;
    }

    .mobileTitle {
      margin-bottom: 50px;
      font-size: 36px;
      font-weight: 400;

      @media screen and (max-width: $mobile-screen) {
        margin-bottom: 40px;
        font-size: 28px;
      }
    }

    .image {
      width: 46vw;
      height: 426px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: 80%;
      background-position-y: center;

      animation: appear-animation .5s ease-out;

      @media screen and (max-width: $small-screen) {
        width: 44vw;
        height: 320px;
      }
      @media screen and (max-width: 900px) {
        animation: none;

        background-position: center;

        width: 100%;
        max-width: unset;
        height: 400px;
      }
      @media screen and (max-width: $mobile-screen) {
        height: 200px;
      }
    }

    .contentText {
      flex: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 11px;

      @media screen and (max-width: $small-screen) {
        margin-right: 0.75vw;
      }
      @media screen and (max-width: 900px) {
        margin-top: 50px;

        width: 100%;
        min-height: 325px;

        display: block;
        margin-right: 0;
      }
      @media screen and (max-width: $mobile-screen) {
        margin-top: 40px;
        min-height: 320px;
      }

      .slide {
        width: 694px;
        max-width: 90%;

        animation: appear-animation .5s ease-out;

        .h2 {
          font-size: 36px;
          line-height: 60px;
          font-weight: 500;
        }

        p {
          margin-top: 42px;
          font-size: 24px;
          line-height: 32px;
        }

        button {
          margin-top: 72px;
          width: 400px;
          height: 84px;
        }

        @media screen and (max-width: $small-screen) {
          width: 500px;

          .h2 {
            font-size: 26px;
            line-height: 40px;
          }

          p {
            margin-top: 30px;
            font-size: 16.5px;
            line-height: 28px;
          }

          button {
            margin-top: 52px;
            width: 300px;
            height: 60px;
          }
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          max-width: unset;
          padding-left: 10%;
          padding-right: 10%;

          button {
            float: right;
          }
        }

        @media screen and (max-width: $mobile-screen) {
          padding-left: 5%;
          padding-right: 5%;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .h2, p {
            width: 100%;
          }

          .h2 {
            font-size: 24px;
            line-height: 40px;
          }

          p {
            font-size: 15px;
            line-height: 25px;
          }
          
          button {
            margin-top: 44px;
            float: unset;
          }
        }

      } 
    }

  }
}

.controls {
  height: 106px;

  @media screen and (max-width: $mobile-screen) {
    height: 86px;
  } 
}

// styling for Boi website
.holderBoi {
  height: 60vh;

  .errorMessageBoi {
    color: $white-color;
  }
}

.presentationBoi {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: $white-color;

  .contentControlsBoi {
    display: flex;
    justify-content: space-between;

    // for 3 items
    // min-width: 1350px;
    // width: 82vw;
    // max-width: 1580px;

    width: 960px;

    .controlHolderBoi {
      cursor: pointer;
      user-select: none;

      width: 434px;

      display: flex;
      justify-content: center;

      .controlBoi {

        .h2Boi {
          text-align: center;
          font-size: 40px;
          font-weight: 400;
        }

        .underlineBoi {
          margin-top: 20px;
          width: 100%;
          height: 0px;
          border: 2.5px solid $nevo-boi-dark-blue-color;
          background-color: $nevo-boi-dark-blue-color;
          opacity: 0;
          transition: left .4s ease;
        } 
        .activeBoi {
          opacity: 1;
          position: absolute;
          left: 0;
        }
      }
    }
    
    .separetorBoi {
      height: 51px;
      border: 0.5px solid rgba($color: $nevo-boi-lighter-grey-color, $alpha: 0.5);
    }

    @media screen and (max-width: $small-screen) {
      // for 3 items
      // min-width: 900px;
      // width: 70vw;
      // max-width: 90%;

      width: 660px;

      .controlHolderBoi {
        width: 305px;

        .controlBoi {
          .h2Boi {
            font-size: 26px;
          }
    
          .underlineBoi {
            margin-top: 12px;
            border-width: 1.5px;
          }
        }
      }
      
      .separetorBoi {
        height: 36px;
      }
    }
    @media screen and (max-width: 900px) {
      min-width: unset;
      width: 100%;
      max-width: 800px;
      
      padding-left: 10%;
      padding-right: 10%;

      flex-wrap: wrap;
      row-gap: 20px;

      .controlHolderBoi {
        width: unset;

        .controlBoi {
          .underlineBoi {
            position: static;
            transition: opacity .25s ease-in-out;
          }
          .activeBoi {
            position: static;
          }  
        }
      }

      .lastSeparatorBoi {
        display: none;
      }
    }
    @media screen and (max-width: $mobile-screen) {
      padding-left: 5%;
      padding-right: 5%;

      row-gap: 10px;

      .controlHolderBoi {
        .controlBoi {
          .h2Boi {
            font-size: 5.6vw;
          }
        }
      }

      .separetorBoi {
        height: 7.3vw;
      }
    }
  }

  .contentBoi {
    margin-top: 160px;
    width: 100vw;

    display: flex;
    align-items: center;

    * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media screen and (max-width: $small-screen) {
      margin-top: 60px;      
    }
    @media screen and (max-width: 900px) {
      margin-top: 0;
      flex-flow: column nowrap;
      align-items: center;
    }

    .mobileTitleBoi {
      margin-bottom: 50px;
      font-size: 36px;
      font-weight: 400;

      @media screen and (max-width: $mobile-screen) {
        margin-bottom: 40px;
        font-size: 28px;
      }
    }

    .imageBoi {
      width: 46vw;
      height: 426px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: 80%;
      background-position-y: center;

      animation: appear-animation .5s ease-out;

      @media screen and (max-width: $small-screen) {
        width: 44vw;
        height: 320px;
      }
      @media screen and (max-width: 900px) {
        animation: none;

        background-position: center;

        width: 100%;
        max-width: unset;
        height: 400px;
      }
      @media screen and (max-width: $mobile-screen) {
        height: 200px;
      }
    }

    .contentTextBoi {
      flex: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 11px;

      @media screen and (max-width: $small-screen) {
        margin-right: 0.75vw;
      }
      @media screen and (max-width: 900px) {
        margin-top: 50px;

        width: 100%;
        min-height: 325px;

        display: block;
        margin-right: 0;
      }
      @media screen and (max-width: $mobile-screen) {
        margin-top: 40px;
        min-height: 320px;
      }

      .slideBoi {
        width: 694px;
        max-width: 90%;

        animation: appear-animation .5s ease-out;

        .h2Boi {
          font-size: 36px;
          line-height: 60px;
          font-weight: 500;
        }

        p {
          margin-top: 42px;
          font-size: 24px;
          line-height: 32px;
        }

        button {
          margin-top: 72px;
          width: 400px;
          height: 84px;
        }

        @media screen and (max-width: $small-screen) {
          width: 500px;

          .h2Boi {
            font-size: 26px;
            line-height: 40px;
          }

          p {
            margin-top: 30px;
            font-size: 16.5px;
            line-height: 28px;
          }

          button {
            margin-top: 52px;
            width: 300px;
            height: 60px;
          }
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          max-width: unset;
          padding-left: 10%;
          padding-right: 10%;

          button {
            float: right;
          }
        }

        @media screen and (max-width: $mobile-screen) {
          padding-left: 5%;
          padding-right: 5%;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .h2Boi, p {
            width: 100%;
          }

          .h2Boi {
            font-size: 24px;
            line-height: 40px;
          }

          p {
            font-size: 15px;
            line-height: 25px;
          }
          
          button {
            margin-top: 44px;
            float: unset;
          }
        }

        .ctaButtonBoi {
          border-radius: 8px;
          width: 50%;
          height: 70px;

          @media screen and (max-width: $mobile-screen) {
            width: 90%;
            height: 55px;
         }
         
         @media screen and (max-width: $tablet-screen) {
            width: 50%;
            height: 55px;
          }
        }

      } 
    }

  }
}

.controlsBoi {
  height: 106px;

  @media screen and (max-width: $mobile-screen) {
    height: 86px;
  } 
}